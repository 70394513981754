import {Component, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Service} from '../../service/service';
import {PDM} from '../model/pdm.model';
import {Caracteristica} from '../model/caracteristica';
import {ItemMaterial} from '../model/item-material';
import {FiltroMaterial} from '../model/filtro-material';
import {Item} from '../model/item.model';
import {ConfirmationComponent} from '@serpro/ngx-dsgovbr';
import {NovoItemMaterial} from '../model/novo-item-material.model';
import {NovoItemMaterialCaracteristica} from '../model/novo-item-material-caracteristica.model';
import {BsModalRef, BsModalService, ModalOptions} from 'ngx-bootstrap/modal';
import {MessageService} from 'primeng/api';
import {AplicacaoService} from '../../service/aplicacao.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-detalhe-material-siasgnet-lote',
  templateUrl: './detalhe-material-siasgnet-lote.component.html',
  styleUrls: ['./detalhe-material-siasgnet-lote.component.css']
})
export class DetalheMaterialSiasgnetLoteComponent implements OnInit, OnDestroy, OnChanges {
  @Output() aoVoltar = new EventEmitter<any>();
  pdm: PDM = new PDM();
  itens: ItemMaterial[] = [];
  codigoMaterialEscolhido: any;
  itensSelecionados: ItemMaterial[] = [];
  itensSelecionadosTela: ItemMaterial[] = [];
  caracteristica: Caracteristica[] = [];
  caracteristicaObrigatorias: any[] = [];
  caracteristicaObrigatoriasFaltantes: any[] = [];
  fitrosSelecionados: FiltroMaterial[] = [];
  item: Item;
  itensArmazenados: Item[] = [];
  unidadesFornecimento: any;
  unidadeFornecimentoEscolhida: any;
  // private selected: any;
  novoItemCodigo: any;
  novoItemValor: any;
  incluirNovoItem: boolean;
  disabledBotaoNovoItem = true;
  novoItemMaterial: any;
  paginarMaterial = true ;
  rows = 10;
  classificacaoContabil: any[] = [];
  itensAtivos: number = 0;
  itensInativos: number = 0;
  ativos: boolean = false;

  @ViewChild('confirmCont', {static: true}) confirmCont: ConfirmationComponent;
  modalRef: BsModalRef;
  @ViewChild('template1', { static: false })  template1: any;

  modalMensagemImpedimentoRef: BsModalRef;  
  @ViewChild('mensagemImpedimento', { static: false }) mensagemImpedimento: any;


  constructor(private route: ActivatedRoute, private location: Location, private service: Service,
              private router: Router, private messageService: MessageService, 
              public app: AplicacaoService, private modalService: BsModalService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.pdm.id = localStorage.getItem('Id');
    this.pdm.descricao = localStorage.getItem('Nome');
    this.pdm.classe     = localStorage.getItem('Classe');
  }

  ngOnInit(): void {
    console.log('DetalheMaterialSiasgnetLoteComponent ngOnInit ');
    this.incluirNovoItem = false;
    this.codigoMaterialEscolhido = localStorage.getItem('codigo_escolhido'); // usado na pesquisa por código
    this.pdm.id = localStorage.getItem('Id');
    this.pdm.descricao = localStorage.getItem('Nome');
    this.pdm.classe    = localStorage.getItem('Classe');
    this.itens = [];
    this.itensSelecionados = [];
    this.caracteristica = [];
    this.fitrosSelecionados = [];
    this.itensArmazenados = [];
    this.classificacaoContabil = [];

    if (this.pdm.id ) { // pesquisa normal por  pdm
      this.service.obterCaracteristicasPorPDM(this.pdm.id).subscribe(caracteristicas => {
        if (caracteristicas){
          for (const caracteristicatmp of caracteristicas) {
            this.caracteristica.push(caracteristicatmp);
            if (caracteristicatmp.caracteristicaObrigatoria === true) {
              this.caracteristicaObrigatorias.push(caracteristicatmp);
            }
          }
          if (caracteristicas.length === 0){
            this.messageService.add({
              severity: 'warn',
              summary: 'Este Material não possui Características Ativas.',
              detail: ''
            });
          }
          // Retirado por requiusição do relato 3073737, do cliente - José Marcos
          //           this.caracteristica.sort((a, b) => (a.caracteristicaObrigatoria < b.caracteristicaObrigatoria) ? 1 : -1);
          for (const caracteristica of this.caracteristica) {
            caracteristica.valorCaracteristica.sort(
              (a: { nomeValorCaracteristica: string; }, b: { nomeValorCaracteristica: string; }) =>
                (a.nomeValorCaracteristica < b.nomeValorCaracteristica) ? -1 : 1);
                // for criado para tirar os valores de caracteristicas inativas(falso)
                for (let ind = 0; ind < caracteristica.valorCaracteristica.length; ind++){
                  if (!caracteristica.valorCaracteristica[ind].statusValorCaracteristicaPdm){
                    caracteristica.valorCaracteristica.splice(ind,1);
                    ind--;
                  }
                }
                for (const valor of caracteristica.valorCaracteristica) {
                  valor.valorLabel = valor.nomeValorCaracteristica;
                  valor.valorConcat = valor.codigoValorCaracteristica;
                  if (valor.siglaUnidadeMedida) {
                    valor.valorLabel = valor.valorLabel + ' ' + valor.siglaUnidadeMedida;
                    valor.valorConcat = valor.valorConcat + '¬' + valor.siglaUnidadeMedida;
                  }
                }                  
            }   
          }
      });
      this.service.obterUnidadeFornecimentoPorPDM(this.pdm.id).subscribe(x => {
        this.unidadesFornecimento = x
        if (this.unidadesFornecimento != null) {
          if (this.unidadesFornecimento.length === 1){
             this.unidadeFornecimentoEscolhida = this.unidadesFornecimento[0];
          }
        }
      });
      this.service.obterClassificacaoContabilPorCodigoPdm(this.pdm.id).subscribe(classificacaoContabil => {
          if (classificacaoContabil){
            for (const tmp of classificacaoContabil) {
              console.log(tmp.classificacaoContabilMaterial);
              this.classificacaoContabil.push(tmp.classificacaoContabilMaterial);
            }
          }
        }
      );
      this.service.obterItemMaterialCaracateríticasValorPorPDM(this.pdm.id).subscribe(item => {
        if (item) {
          for (const tmp of item) {
//            esteves
//            if (tmp.itemSuspenso == true) {
//               tmp.statusItem = false;
//            }
            this.itens.push(tmp);
            this.itensSelecionados.push(tmp);
          }
          this.atualizarContagem();
          this.somenteAtivos(); 

          this.itensSelecionados.sort((a: any, b: any) => {
            if(a.statusItem == true && b.statusItem == true){
              return 0;
            }
            if(a.statusItem == true && b.statusItem == false){
              return -1;
            }
            return 1;
          });
          if (this.itens.length === 0){
            this.messageService.add({
              severity: 'warn',
              summary: 'Este Material não possui Ítens Ativos.',
              detail: 'Porém você pode criar um item escolhendo as características obrigatórias e outras'
            });
          }
        }
      });
    }
    if (this.codigoMaterialEscolhido ){ // pesquisa por id de material
      console.log('codigoMaterialEscolhido pesquisa por id de material' + this.codigoMaterialEscolhido);
      this.service.obterMaterialPorCodigo(this.codigoMaterialEscolhido).subscribe(materialRetorno => {
        if (materialRetorno) {
          this.itens.push(materialRetorno); // coloco os dados array geral da pesquisa
          this.itensSelecionados.push(materialRetorno); // coloco array da tela
          this.itensSelecionados.sort((a: any, b: any) => {
            if(a.statusItem == true && b.statusItem == true){
              return 0;
            }
            if(a.statusItem == true && b.statusItem == false){
              return -1;
            }
            return 1;
          });
          this.pdm.id = materialRetorno.codigoPdm;
          this.pdm.descricao = materialRetorno.nomePdm;
          this.service.obterUnidadeFornecimentoPorPDM(this.pdm.id).subscribe(x => {
            this.unidadesFornecimento = x
            if (this.unidadesFornecimento != null) {
              if (this.unidadesFornecimento.length === 1){
                 this.unidadeFornecimentoEscolhida = this.unidadesFornecimento[0];
              }
            }
          });
          this.service.obterClassificacaoContabilPorCodigoPdm(this.pdm.id).subscribe(classificacaoContabil => {
              if (classificacaoContabil){
                for (const tmp of classificacaoContabil) {
                  console.log(tmp.classificacaoContabilMaterial);
                  this.classificacaoContabil.push(tmp.classificacaoContabilMaterial);
                }
              }
            }
          );
          this.atualizarContagem();
          this.somenteAtivos();
        }
      }, (error) => console.error(error));

    }
    this.getItensArmazenados();
    // localStorage.setItem('Id', '');
    // localStorage.setItem('Nome', '');
    // localStorage.setItem(('codigo_escolhido'), '');
  }

  ngOnDestroy(): void {
    // this.subscription.unsubscribe();
  }


  public abrirModalMensagemImpedimento(): void {
    const modalOptions: ModalOptions = {
    class: 'modal-lg modal-dialog-centered'
    };
    this.modalMensagemImpedimentoRef = 	this.modalService.show(this.mensagemImpedimento, modalOptions);
  }

  public fecharModalMensagemImpedimento(): void{
    this.modalMensagemImpedimentoRef.hide();
  }


  getItensArmazenados(): void{
    const saved = localStorage.getItem('itens');
    if (saved != null){
      this.itensArmazenados = JSON.parse(saved);
    }
  }

  escolher(codigoItem: number): void {
    // tenho o id, tenho que pegar o nome
    let nomeCompleto = '';
    let carrinhoNome = '';
    let carrinhoCaracteristicas = '';
    let sustentavel = false;
    let codigoNcm: string= '';
    let aplicaMargemPreferencia: boolean = false;

    for (const itemMaterialTmp  of this.itensSelecionados) {
      if (itemMaterialTmp.codigoItem === codigoItem){
        nomeCompleto = itemMaterialTmp.nomePdm;
        carrinhoNome =  itemMaterialTmp.nomePdm;
        sustentavel = itemMaterialTmp.itemSustentavel;
        codigoNcm = itemMaterialTmp.codigoNcm;
        aplicaMargemPreferencia = itemMaterialTmp.aplicaMargemPreferencia;
        for (const buscaItemCaracteristica of itemMaterialTmp.buscaItemCaracteristica) {
          nomeCompleto = nomeCompleto + ' ' + buscaItemCaracteristica.nomeCaracteristica + ': ' +
           buscaItemCaracteristica.nomeValorCaracteristica + ' '
            + (buscaItemCaracteristica.siglaUnidadeMedida == null ? "" : buscaItemCaracteristica.siglaUnidadeMedida) +',';
              if (buscaItemCaracteristica.statusCaracteristica == true){
                   carrinhoCaracteristicas  =  carrinhoCaracteristicas + '#' + buscaItemCaracteristica.nomeCaracteristica + ': ' +
                   buscaItemCaracteristica.nomeValorCaracteristica + " "
                   + (buscaItemCaracteristica.siglaUnidadeMedida == null ? "" : buscaItemCaracteristica.siglaUnidadeMedida) + '|';
              }else{
                   carrinhoCaracteristicas  =   carrinhoCaracteristicas + '&' + buscaItemCaracteristica.nomeCaracteristica + ': ' +
                   buscaItemCaracteristica.nomeValorCaracteristica + " "
                    + (buscaItemCaracteristica.siglaUnidadeMedida == null ? "" : buscaItemCaracteristica.siglaUnidadeMedida) + '|';
              }


        }
      }
    }

    if (this.unidadeFornecimentoEscolhida === null || this.unidadeFornecimentoEscolhida === undefined || this.unidadeFornecimentoEscolhida === ''){
      this.messageService.add({
        severity: 'warn',
        summary: 'Não é possível adicionar um material sem uma unidade de fornecimento.',
        detail: 'Escolha uma unidade de fornecimento.'
      });
      return;
    }

    const nomeUnidadeMedida = {
      nomeUnidadeMedida: this.unidadeFornecimentoEscolhida.nomeUnidadeFornecimento};

     console.log("UF= " + this.unidadeFornecimentoEscolhida.siglaUnidadeMedida)
    if (this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== 0){
      if (this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== null) {
         nomeUnidadeMedida.nomeUnidadeMedida += ' ' + this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida;
      } else {
         nomeUnidadeMedida.nomeUnidadeMedida += ' ' + "";
      }
    }
    // tslint:disable-next-line:max-line-length
    if ( this.unidadeFornecimentoEscolhida.siglaUnidadeMedida !== null){
      nomeUnidadeMedida.nomeUnidadeMedida += ' ' + this.unidadeFornecimentoEscolhida.siglaUnidadeMedida;
    } else {
      nomeUnidadeMedida.nomeUnidadeMedida += ' ' + "";
    }

    this.item = new Item();
    this.item.id = codigoItem;
    this.item.descricao = this.pdm.descricao;
    this.item.descricaoDetalhada = nomeCompleto;
    this.item.nome = nomeCompleto;
    this.item.carrinhoNome = carrinhoNome;
    this.item.sustentavel = sustentavel;
    this.item.carrinhoCaracteristicas = carrinhoCaracteristicas;
    this.item.tipo = 'MATERIAL';
    this.item.type = 'M';
    this.item.sustentavel = sustentavel;
    this.item.unidade = nomeUnidadeMedida;
    this.item.descricao = this.pdm.descricao;
    this.item.codigoNcm = codigoNcm;
    this.item.aplicaMargemPreferencia = aplicaMargemPreferencia;
    // esteves
    this.item.siglaUnidadeMedida = this.unidadeFornecimentoEscolhida.siglaUnidadeMedida;
    this.item.nomeUnidadeMedida  = this.unidadeFornecimentoEscolhida.nomeUnidadeMedida;
//    this.item.nomeUnidadeMedida  = this.unidadeFornecimentoEscolhida.nomeUnidadeFornecimento;
    this.item.nomeUnidadeFornecimento  = this.unidadeFornecimentoEscolhida.nomeUnidadeFornecimento;
    this.item.siglaUnidadeFornecimento = this.unidadeFornecimentoEscolhida.siglaUnidadeFornecimento;
    this.item.capacidadeUnidadeFornecimento = this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida;

    this.itensArmazenados.push(this.item);
    localStorage.setItem('itens', JSON.stringify(this.itensArmazenados));

    this.messageService.add({
          severity: 'success',
          summary: 'Item armazenado na lista.'
    });
  }

  selecionados(): void {
    this.router.navigate(['/exibirselecionadossiasgnetlote']);
  }

  naovalidar(codigoItem: number): boolean{
    for (const item of this.itensArmazenados) {
      if (item.id === codigoItem ) {
        return true;
      }
    }
    return false;
  }


  voltar(): void {
    // this.location.back();
    this.aoVoltar.emit();

  }

  enviarNovoItem2(): void {
    this.messageService.add({severity: 'warn',
      summary: 'Transação indisponível no momento - procure orientação em:',
      detail: 'https://www.gov.br/compras/pt-br/centrais-de-conteudo/manuais/manual-siasg/manual-siasg-catalogo/manual-catmat-e-catser-2020.pdf '});
  }


  enviarNovoItem(): void {
    this.novoItemMaterial = new NovoItemMaterial(); // const novoItemMaterial = new NovoItemMaterial();
    this.novoItemMaterial.pdm = this.pdm.id;
    this.novoItemMaterial.classe = this.pdm.classe;
    this.novoItemMaterial.sustentavel = 'N';
    this.novoItemMaterial.exclusivoCentralCompras = 'N';
    let novoItemMaterialCaracteristica;
    for (const filtrotmp of this.fitrosSelecionados) { // console.log(JSON.stringify(filtrotmp));
      novoItemMaterialCaracteristica = new NovoItemMaterialCaracteristica();
      novoItemMaterialCaracteristica.codigoValorCaracteristica = filtrotmp.codigoValorCaracteristica;
      novoItemMaterialCaracteristica.codigoCaracteristica = filtrotmp.codigoCaracteristica;
      novoItemMaterialCaracteristica.siglaUnidadedeMedida = filtrotmp.siglaUnidadeMedida;
      this.novoItemMaterial.novoItemMaterialCaracteristica.push(novoItemMaterialCaracteristica);
    }
    /*  validar características obrigatórias */
    if (this.validarNovoItem(this.novoItemMaterial)) {
      this.service.criarNovoItemMaterial(this.novoItemMaterial).subscribe( dados => {
          if (dados){
            if (dados.codigoRetorno === 201){
              this.atualizarItensPDM();
              this.messageService.add({severity: 'success', summary: 'Novo item incluido com successo.', detail: ''});
              this.incluirNovoItem = false;
            }else {
              this.messageService.add({severity: 'error', summary: 'Não foi possível criar novo item. Entre em contato com área responsável ou tente mais tarde.', detail: dados.codigoRetorno});
            }
          }
        }, error => {
          this.messageService.add({severity: 'error', summary: 'Não foi possível criar novo item. Entre em contato com área responsável ou tente mais tarde.', detail: error});

        }
      );
      console.log('Enviar - NovoItemMaterial '); console.log(JSON.stringify(this.novoItemMaterial));
      this.incluirNovoItem = false;
    }else {
      this.messageService.add({severity: 'error', summary: 'Para criar um novo item é  preciso selecionar os valores para todas as características obrigatórias.',
        detail: this.caracteristicaObrigatoriasFaltantes.toString()});

    }

  }

  mudarFiltro(event: any, codigoCaracteristica: string, numeroCaracteristica: number): void {
    // inicio do filtro, limpar o arrays de filtros
    const evento: string = event.target.value;
    this.itensSelecionados = [];
    this.itensSelecionados = this.itens;
    let novosSelecionados = this.itensSelecionados;
    const valoresEvent = evento.split('|');
    console.log(codigoCaracteristica); console.log(numeroCaracteristica); console.log(event.target.value);
    /* se  o codigo valor caracteristica for 0, um filtro foi retirado */
    if (event.target.value === '0') {
      // retirar o filtro da lista de acordo com o código da característica
      this.fitrosSelecionados = this.fitrosSelecionados.filter(x => x.codigoCaracteristica !== codigoCaracteristica);
    } else{/* se  o codigo valor caracteristica for diferente de 0, significa que é filtro novo ou troca de valor de filtro
      verificar se o filtro já existe e neste caso trocar o valor,senão criar novo filtro */
      if (this.fitrosSelecionados.some (x => x.codigoCaracteristica === codigoCaracteristica)){
        for (const filtro of this.fitrosSelecionados) {
          if (filtro.codigoCaracteristica === codigoCaracteristica){
            filtro.codigoValorCaracteristica = valoresEvent[0];
            filtro.siglaUnidadeMedida = valoresEvent[1];
          }
        }
      }else{
        const filtro = new FiltroMaterial();
        filtro.codigoValorCaracteristica = valoresEvent[0];
        filtro.siglaUnidadeMedida = valoresEvent[1];
        filtro.codigoCaracteristica = codigoCaracteristica;
        this.fitrosSelecionados.push(filtro);
      }
    }
    /*
   recarregar tudo a partir da lista de filtro atualizada
    */
    for (const filtrotmp of this.fitrosSelecionados) {
      console.log('filtro' + filtrotmp.codigoCaracteristica);
      const listaItnes: ItemMaterial[] = [];
      for (const item of novosSelecionados){
        for (const buscaItemCaracteristica of item.buscaItemCaracteristica) {
          if ( buscaItemCaracteristica.codigoCaracteristica  === filtrotmp.codigoCaracteristica){
            if ( buscaItemCaracteristica.codigoValorCaracteristica === filtrotmp.codigoValorCaracteristica){
              if (!(listaItnes.some (x => x.codigoItem === item.codigoItem))){
                listaItnes.push(item);
              }
            }
          }
        }
      }
      novosSelecionados = [];
      novosSelecionados = listaItnes;
    }

    this.itensSelecionados = [];
    this.itensSelecionados = novosSelecionados;
    this.atualizarContagem();
    this.somenteAtivos();

    // if ( this.itensSelecionados.length === 0){  // se a lista de itens fica vazia, oferece criar novo item de material
    //       this.gravarNovoItemCaracateristica(valoresEvent[0], codigoCaracteristica); // guarda a caracteristica escolhida
    //       if (this.incluirNovoItem === false){
    //         this.confirmCont.show(null); // abre a caixa de dialogo e se for ok, vai chamar o metodo novoItem()
    //       }
    //       // this.modalService.show(this.template1);
    //     }

    // José Marcos - Demanda 3793528 - Retirar botão Novo Item e alterar mensagem para o usuário. 21/09/23   
    if ( this.itensSelecionados.length === 0){ // verificar se pode criar o botão novo

      this.abrirModalMensagemImpedimento();
      this.incluirNovoItem = false;

    //   if (this.validarCaracteristicasObrigatorias()){// se sim, cria o botao novo e
    //     // da um aviso que já pode criar ou até escolher mais características
    //     if (!this.incluirNovoItem){
    //       this.messageService.add({
    //         severity: 'success',
    //         summary: 'Item não encontrado, você pode criar este item agora ou ainda escolher mais características.',
    //         detail: '.'
    //       });
    //     }
    //     this.gravarNovoItemCaracateristica(valoresEvent[0], codigoCaracteristica);
    //     console.log('validou');
    //     this.incluirNovoItem = false;
    //   }else{// se não, dar um alerta avisando da possibilidade e da obrigatoriedade
    //     this.messageService.add({
    //       severity: 'success',
    //       summary: 'Item não encontrado, porém você poderá criar um novo item escolhendo as características obrigatórias',
    //       detail: '.'
    //     });
    //     console.log('não validou');
    //     this.incluirNovoItem = false;
    //   }
    // }else {
    //   this.incluirNovoItem = false;
    }
  }

  IniciaNovoItem(): void {
    this.incluirNovoItem = true;
    this.incluirNovoItem = true;
    this.messageService.add({
      severity: 'error', summary: 'Iniciado o processo para criação de itens, escolha as  características obrigatórias e outras e clique no botão criar novo item.',
      detail: this.caracteristicaObrigatoriasFaltantes.toString()});
    if (this.caracteristicaObrigatoriasFaltantes.length === 0){
      this.disabledBotaoNovoItem = false;
    }
  }
  /* objetivo: verificar se um item novo tem todas as características obrigatórias */
  private validarNovoItem(novoItemMaterial: NovoItemMaterial): boolean{
    this.caracteristicaObrigatoriasFaltantes = [];
    let retorno = false;
    for (const caractmp of this.caracteristicaObrigatorias) {
      if (!novoItemMaterial.novoItemMaterialCaracteristica.some(x => x.codigoCaracteristica === caractmp.codigoCaracteristica)){
       this.caracteristicaObrigatoriasFaltantes.push(caractmp.nomeCaracteristica);
      }
    }
    if (this.caracteristicaObrigatoriasFaltantes.length === 0){
      retorno = true;
    }
    return retorno;
  }


  private carregarCaracteristicasObrigatorias(): void{
    console.log('passando por carregarCaracteristicasObrigatorias');
    for (const caracteristica of this.caracteristica){
      console.log('teste teste teste ');
      console.log(JSON.stringify(caracteristica));
    }
    for (const caracteristicaElement of this.caracteristicaObrigatorias) {
      console.log(caracteristicaElement);
    }
  }
    private atualizarItensPDM(): void{
      console.log('itens pdm atualizado');
      this.service.obterItemMaterialCaracateríticasValorPorPDM(this.pdm.id).subscribe( item => {
        if (item){
          for (const tmp of item) {
            this.itens.push(tmp); // coloco os dados array geral da pesquisa
            this.itensSelecionados.push(tmp); // coloco array da tela
          }
          this.itensSelecionados.sort((a: any, b: any) => {
            if(a.statusItem == true && b.statusItem == true){
              return 0;
            }
            if(a.statusItem == true && b.statusItem == false){
              return -1;
            }
            return 1;
          });
          this.recarregarFiltros();
        }
      });


    }

  private gravarNovoItemCaracateristica(value: any, codigoCaracteristica: string): void {
   //  console.log('gravando codigo e carecterística para criar novo');
    this.novoItemCodigo = codigoCaracteristica;
    this.novoItemValor = value;
  }

  private recarregarFiltros(): void { //
    console.log('recarregar Filtros');  console.log('');
    console.log('this.novoItemCodigo: ' + this.novoItemCodigo);
    this.itensSelecionados = [];
    this.itensSelecionados = this.itens;
    let novosSelecionados = this.itensSelecionados;
    console.log('this.fitrosSelecionados');
    console.log(JSON.stringify(this.fitrosSelecionados));

    if (this.novoItemValor === '0') {
      console.log('removeu');
     //  this.fitrosSelecionados = this.fitrosSelecionados.filter(x => x.codigoCaracteristica !== this.novoItemCodigo);
    } else {
      if (this.fitrosSelecionados.some(x => x.codigoCaracteristica === this.novoItemCodigo)) {
        for (const filtro of this.fitrosSelecionados) {
          if (filtro.codigoCaracteristica === this.novoItemCodigo) {
            filtro.codigoValorCaracteristica = this.novoItemValor;
          }
        }
      } else {
        const filtro = new FiltroMaterial();
        filtro.codigoValorCaracteristica = this.novoItemValor;
        filtro.codigoCaracteristica = this.novoItemCodigo;
        this.fitrosSelecionados.push(filtro);
      }
    }
    console.log('this.fitrosSelecionados depois dos filtros');
    console.log(JSON.stringify(this.fitrosSelecionados));
    /*
   recarregar tudo a partir da lista de filtro atualizada
    */
    for (const filtrotmp of this.fitrosSelecionados) {
      console.log('filtro' + filtrotmp.codigoCaracteristica);
      const listaItnes: ItemMaterial[] = [];
      for (const item of novosSelecionados) {
        for (const buscaItemCaracteristica of item.buscaItemCaracteristica) {
          if (buscaItemCaracteristica.codigoCaracteristica === filtrotmp.codigoCaracteristica) {
            if (buscaItemCaracteristica.codigoValorCaracteristica === filtrotmp.codigoValorCaracteristica) {
              if (!(listaItnes.some(x => x.codigoItem === item.codigoItem))) {
                listaItnes.push(item);
              }
            }
          }
        }
      }
      novosSelecionados = [];
      novosSelecionados = listaItnes;
    }
    this.itensSelecionados = [];
    this.itensSelecionados = novosSelecionados;
  }


  private validarCaracteristicasObrigatorias(): boolean {
    let retorno = true;
    for (const caractmp of this.caracteristicaObrigatorias) {
      if (!this.fitrosSelecionados.some (x => x.codigoCaracteristica === caractmp.codigoCaracteristica)){
        retorno = false;
      }
    }
    return retorno;
  }

  limparFiltros(): void {
    this.ngOnInit();
  }

  somenteAtivos(){
    let auxArray = [];
    this.itensSelecionadosTela = [];
    if(this.ativos == false){
      for(let i of this.itensSelecionados){
        if(i.statusItem == true && i.itemSuspenso == false){
          auxArray.push(i); 
        } 
      }
    } else {
        for(let i of this.itensSelecionados){
          if(i.statusItem == true){
            auxArray.push(i);
          }  
        }
        auxArray.sort((a, b) => (a.itemSuspenso > b.itemSuspenso) ? -1 : 1);
    }
    this.itensSelecionadosTela = auxArray;
  }

  atualizarContagem() {
    this.itensAtivos = 0;
    this.itensInativos = 0;
    for (const i of this.itensSelecionados) {
      if (i.statusItem == true && i.itemSuspenso == false) {
        this.itensAtivos = this.itensAtivos + 1;
      } 
      if (i.statusItem == true && i.itemSuspenso == true){
        this.itensInativos = this.itensInativos + 1;
      }
    }
  }
}
