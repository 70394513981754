import {Component, DoCheck, EventEmitter, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {Item} from '../model/item.model';
import {Service} from '../../service/service';
import {Router} from '@angular/router';
import { SortEvent } from 'primeng/api';
import {AplicacaoService} from '../../service/aplicacao.service';
import {interval, Subscription} from 'rxjs';
import {MessageService} from 'primeng/api';
import { ConfirmationComponent } from '@serpro/ngx-dsgovbr';

@Component({
  selector: 'app-exibir-selecionados-siasgnet-lote',
  templateUrl: './exibir-selecionados-siasgnet-lote.component.html',
  styleUrls: ['./exibir-selecionados-siasgnet-lote.component.css']
})
export class ExibirSelecionadosSiasgnetLoteComponent implements OnInit {
  @Output() aoVoltar = new EventEmitter<any>();
  itens: Item[] = [];
  itenssaida: Item[] = [];
  private subscription: Subscription;
  excluirId: number;
  itensExcluidos: Array<Item[]> = [];

  @ViewChild('confirmExclusao', {static: true}) confirmExclusao: ConfirmationComponent;


  constructor(private router: Router, public app: AplicacaoService,   private messageService: MessageService ) { }

  ngOnInit(): void {
    this.getItens();
    this.getExcluidos();

    // this.subscription = interval(1000).subscribe(x => {
    //   this.app.counter = --this.app.counter;
    //   // console.log('timer' + this.app.counter);
    //   if ( this.app.counter === this.app.counter_blink){
    //     this.messageService.add({severity:'info', summary:'Salve seu trabalho.', detail:'Tempo de Conexão se esgotando.'});
    //   } else if (this.app.counter === 0) {
    //     this.messageService.add({severity:'info', summary:'Tempo de Conexão Esgotado', detail:''});
    //   }
    // });
  }

  getItens(): Item[]{
    const saved = localStorage.getItem('itens');
    if (saved != null){
      this.itens = JSON.parse(saved);
    }
    return this.itens;
  }

  getExcluidos(): Array<Item[]>{
    const saved = localStorage.getItem('excluidos');
    if (saved != null){
      this.itensExcluidos = JSON.parse(saved);
    }
    return this.itensExcluidos;
  }

  ordenar(){
    localStorage.setItem('itens', JSON.stringify(this.itens));
  }

  excluir( id: number): void {

    let itemExcluido;

    for (let i = 0; i < this.itens.length; i++) {
      if ( this.itens[i].id === id ){
       itemExcluido = this.itens.splice(i, 1);
       break;
      }
    }
    localStorage.setItem('itens', JSON.stringify(this.itens));

    this.getExcluidos();

    if(itemExcluido != null){
      this.itensExcluidos.push(itemExcluido);
      localStorage.setItem('excluidos', JSON.stringify(this.itensExcluidos));
    }

    this.getItens();
    this.messageService.add({
      severity: 'success',
      summary: 'Item excluído da lista.'
    });
  }

  openModal1(id: number): void{
    this.confirmExclusao.show(null);
    this.excluirId = id;
  }

  continuar(): void {
    // this.router.navigate(['/buscasiasgnetlote']);
    this.aoVoltar.emit();

  }

  novaPesquisa(): void {

    this.getExcluidos();
    if(this.itens != null){
      this.itensExcluidos.push(this.itens);
      localStorage.setItem('excluidos', JSON.stringify(this.itensExcluidos));
    }

    this.itens = new Array();
    localStorage.setItem('itens', JSON.stringify(this.itens));
   // this.router.navigate(['/buscasiasgnetlote']);
    this.voltar();
  }

  desfazer(){

    this.getExcluidos()
    let itemExcluido = this.itensExcluidos.pop();

    if(itemExcluido != null){

     for (let item of itemExcluido){
        this.itens.push(item);
      }
        localStorage.setItem('excluidos', JSON.stringify(this.itensExcluidos));
        localStorage.setItem('itens', JSON.stringify(this.itens));
      }
    this.getItens();
  }

  voltar(): void {
     // this.router.navigate(['/buscasiasgnetlote']);
    this.aoVoltar.emit();
  }

 adiciona_siasgnet(): void {
    this.itenssaida = [];
    let item;
    let itemtmp;
    let descricaoTemp;
    for (item of this.itens) {      
      itemtmp = new Item();
      if( item.tipo == 'SERVIÇO' ){
         itemtmp.id = item.id;
         itemtmp.descricaoDetalhada = item.descricaoDetalhada;
         itemtmp.type = item.type;
         itemtmp.sustentavel = false;
         itemtmp.descricao = item.descricao;
         itemtmp.unidade = item.unidade;

         itemtmp.nomeUnidadeMedida = item.nomeUnidadeMedida;
         itemtmp.siglaUnidadeMedida = item.siglaUnidadeMedida;

      } else {
         descricaoTemp = item.descricaoDetalhada;
         for (var i=descricaoTemp.length; i>=0; i--) {
           if(descricaoTemp.substring(i) === ",") {
              descricaoTemp = descricaoTemp.substring(0, i-1);
              break;
           }
         }
         itemtmp.id = item.id;
         itemtmp.descricaoDetalhada = descricaoTemp;
         itemtmp.type = item.type;
         itemtmp.sustentavel = item.sustentavel;
         itemtmp.descricao = item.descricao;
         itemtmp.codigoNcm = item.codigoNcm;
         itemtmp.aplicaMargemPreferencia = item.aplicaMargemPreferencia;

         itemtmp.unidade = item.unidade;
 // esteves
         itemtmp.capacidadeUnidadeFornecimento =  item.capacidadeUnidadeFornecimento == 0 ? "" : item.capacidadeUnidadeFornecimento ;
         itemtmp.nomeUnidadeFornecimento =  item.nomeUnidadeFornecimento;
         itemtmp.siglaUnidadeFornecimento =  item.siglaUnidadeFornecimento == "null" || item.siglaUnidadeFornecimento == null ? "" : item.siglaUnidadeFornecimento;
         if (item.nomeUnidadeMedida == "null" || item.nomeUnidadeMedida == null){
            itemtmp.nomeUnidadeMedida = '';
         }else{
            itemtmp.nomeUnidadeMedida = item.nomeUnidadeMedida;
         }
         if (item.siglaUnidadeMedida == "null" || item.siglaUnidadeMedida == null){
            itemtmp.siglaUnidadeMedida = '';
         }else{
            itemtmp.siglaUnidadeMedida = item.siglaUnidadeMedida;
         }
 //        itemtmp.siglaUnidadeMedida = item.siglaUnidadeMedida == "null" || item.siglaUnidadeMedida == null ? "" : item.siglaUnidadeMedida;
      };
      this.itenssaida.push(itemtmp);
    }

    console.log(this.itenssaida);
    window.opener.postMessage(this.itenssaida , '*');

    this.itens = new Array();
    localStorage.setItem('itens', JSON.stringify(this.itens));

    this.itenssaida = [];
    window.close();
  }

  customSort(event: any) {
    event.data.sort((data1: any, data2: any) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

     if (event.field === 'id'){
       value1 = parseInt(value1);
       value2 = parseInt(value2);
     }
     if (value1 == null && value2 != null)
        result = -1;
     else if (value1 != null && value2 == null)
        result = 1;
     else if (value1 == null && value2 == null)
        result = 0;
     else if (typeof value1 === 'string' && typeof value2 === 'string')
        result = value1.localeCompare(value2);
     else
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;

     return (event.order * result);
    });
  }
}



