import {Component, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Service} from '../../service/service';
import {Router} from '@angular/router';
import {Item} from '../model/item.model';
import {Classe} from '../model/classe.model';
import {Grupo} from '../model/grupo.model';
import {MessageService, SelectItem} from 'primeng/api';
import {AplicacaoService} from '../../service/aplicacao.service';
import {interval, Subscription} from 'rxjs';
import {AutoComplete} from 'primeng/autocomplete';
import {DetalheMaterialSiasgnetLoteComponent} from '../detalhe-material-siasgnet-lote/detalhe-material-siasgnet-lote.component';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-busca',
  templateUrl: './busca-siasgnet-lote.component.html',
  styleUrls: ['./busca-siasgnet-lote.component.css']
})
export class BuscaSiasgnetLoteComponent implements OnInit, OnDestroy {
  itens: Item[] = [];
  texto: any | string | number;
  textoBusca: any | string | number;
  servicos: any; servicosRetornados: any;
  materiais: any; materiaisRetornados: any;
  tipoSelecionado: string;
  filtros: Filtro[];
  filtro1: Filtro;
  rows = 10;
  loading: any;
  isDisabled = true;
  currentItem: any;
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  gruposServico: Grupo[] = new Array();
  selectedGrupos: Grupo[] = new Array();
  classesMaterial: Classe[] = new Array();
  selectedClasses: Classe[] = new Array();
  selectedGrupo: Grupo = new Grupo();
  selectedClasse: Classe = new Classe();
  // painelSelecionadoServico: false ;
  // painelSelecionadoMaterial: true ;
  indexpainel = 0;
  sortKey: any;
  paginarServico = true;
  paginarMaterial = true;
  results: any;
  exibirMenssagemVazia = false;
  private subscription: Subscription;
  tela1 = false;
  telaVazia = true;
  detalheServicolVisivel = false;
  detalheMaterialVisivel = false;
  exibirSelecinados = false;
  modalRef1: BsModalRef;
  eventosReceiveMessage = 0;
  pesquisando = false;

  @ViewChild('hint', { static: true }) hint: AutoComplete;
  @ViewChild('childMaterial') childMaterial: DetalheMaterialSiasgnetLoteComponent;
  @ViewChild('template1', {static: false} ) template1: any;
  // @HostListener('window:message', ['$event']) onMessage(event: any): void {
  //   this.receiveMessage(event);
  // }
  constructor(private service: Service, private router: Router, private messageService: MessageService,
              public app: AplicacaoService, private modalService: BsModalService) {
    this.filtros = [
      { name: 'Todos', code: 1 },
      { name: 'Material', code: 2 },
      { name: 'Serviço', code: 3 }
    ];
    this.tipoSelecionado = '';
  }


  ngOnInit(): void {

    this.getItens();
    this.filtro1 = new Filtro();
    this.filtro1.code = 1;
    this.pesquisando = false;

    this.subscription = interval(1000).subscribe(x => {
      this.app.counter = --this.app.counter;
      //  console.log('timer' + this.app.counter);
      if (this.app.counter === this.app.counter_blink) {
        this.messageService.add({ severity: 'warn', summary: 'Salve seu trabalho.', detail: 'Tempo de Conexão se esgotando.' });
      } else if (this.app.counter === 0) {
        this.messageService.add({ severity: 'warn', summary: 'Tempo de Conexão Esgotado.', detail: '' });
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  private receiveMessage(event: any): void {
    this.eventosReceiveMessage = this.eventosReceiveMessage + 1; // console.log(this.eventos);
    if (window.opener){ // console.log('tem um opener');
      if (this.eventosReceiveMessage === 3 ){ // console.log('vou testar  terceiro evento' + this.eventos);
        if (event.data.sistema !== 'Siasgnet'){
          this.router.navigate(['/erro']); // console.log('navegar para erro');
        }
      }
    }else{  // console.log('não tem um opener navegar para erro');
      this.router.navigate(['/erro']);
    }
  }

  pesquisarLupa(): void{
      this.pesquisando = true;
      this.resetArrays();
      this.texto = this.textoBusca;
      this.pesquisar();
      this.pesquisando = false;
  }

  async pesquisar(): Promise<any> {
    this.esconderTodos();
    this.resetArrays();
    this.selectedGrupo = new Grupo();
    this.selectedClasse = new Classe();
    if (!isNaN(this.texto)) {
     // this.obterMateriaServicoPorCodigoNovo(); remover
      const quantidadeServicoCodigo = await this.existeObterServicoPorCodigo();
      const quantidadeMaterialCodigo = await this.existeObterMaterialPorCodigo();
      if (quantidadeMaterialCodigo + quantidadeServicoCodigo === 0){
        this.exibirMenssagemVazia = true;
        return ;
      }
      if (quantidadeMaterialCodigo + quantidadeServicoCodigo > 1){
        this.modalRef1 = this.modalService.show(this.template1); // falta fazer o dialogo
        return ;
      }
      if (quantidadeServicoCodigo  > 0){
        this.obterServicoPorCodigo();
      }
      if (quantidadeMaterialCodigo  > 0){
        this.obterMaterialPorCodigo();
      }

    } else {
      const quantidadeServico = await this.obterServicosPorPalavra(); // this.obterServicosPorPalavra();
      const quantidadeMaterial = await this.obterPDMPorPalavra(); // this.obterPDMPorPalavra();
      const totalRegistros =  quantidadeServico + quantidadeMaterial;
      if (totalRegistros !== 0){
        this.telaVazia = false;
        this.tela1 = true;
        this.exibirMenssagemVazia = false;
      }else{
        this.exibirMenssagemVazia = true;
      }
    }
    //   this.tela1 = true;
  //   this.telaVazia = false;
  }
  async pesquisarEnter(event: any): Promise<any> {
    const text = event.target.value;
    this.texto = text;
    if (text.length >= 2) {
        this.pesquisando = true;
        this.resetArrays();
        setTimeout(() => this.hint.hide(), 200);
        this.pesquisar();
        this.pesquisando = false;
    }
  }

  async buscaEscolhido(codigo: any, tipo: any, nome: any): Promise<any> {
    console.log('buscaEscolhido' + codigo + tipo + nome);
    this.texto = nome;
    this.esconderTodos();
    this.resetArrays();
    if (tipo === 'M') { // material
        this.pesquisando = true;
        this.resetArrays();
        console.log('material');
        localStorage.setItem('Id', codigo);
        localStorage.setItem('Nome', nome); // this.router.navigate(['/detalhematerialsiasgnetlote/']);
        this.tela1 = false;
        this.detalheMaterialVisivel = true;
        if (this.childMaterial !== undefined) {
          this.childMaterial.ngOnInit();
        }
        this.texto = '';
        this.pesquisando = false;
    }
    if (tipo === 'S') { // servico
      console.log('serviço');
        this.pesquisando = true;
        this.resetArrays();
        this.service.obterServicoPorCodigo(codigo).subscribe(servico => {
          if (servico) {
            this.servicos = []; this.servicosRetornados = [];
            this.servicos.push(servico);
            this.servicosRetornados.push(servico);
            this.carregarGrupoServico(servico);
            this.texto = '';
            this.tela1 = true;
            // this.detalheServicolVisivel = true;
          }
        }, (error) => console.error(error));
        this.pesquisando = false;
    }
    if (tipo === 'Todos') { // servico
        this.pesquisando = true;
        this.resetArrays();
        await this.pesquisar();
        // this.texto = '';
        this.pesquisando = false;
    }
    // this.texto = '';

  }
  searchHint(event: any): void {
    console.log(event.query);
    if (event.query.length >= 2) {
      this.service.obterPDMeServicoPorPalavra(event.query).subscribe(data => {
        const x = {
          codigo: '',
          tipo: 'Todos',
          nome: event.query
        };
        data.push(x);
        this.results = data;
      });
    }
  }
  private carregarGrupoServico(servico: any): void {
    if (!this.gruposServico.some(e => e.id === servico.codigoGrupo)) { // se o grupo na contem o código
      let grupo;
      grupo = new Grupo();
      grupo.id = servico.codigoGrupo;
      grupo.descricao = servico.nomeGrupo;
      grupo.code = servico.codigoGrupo;
      grupo.name = servico.codigoGrupo + ' - ' + servico.nomeGrupo;
      this.gruposServico.push(grupo);
    }
  }
  private carregarClasseMaterial(material: any): void {
    // console.log(material.codigoClasse);
    if (!this.classesMaterial.some(e => e.id === material.codigoClasse)) { // se o grupo na contem o código
      let classe;
      classe = new Classe();
      classe.id = material.codigoClasse;
      classe.descricao = material.nomeClasse;
      classe.code = material.codigoClasse;
      classe.name = material.codigoClasse + '-' + material.nomeClasse;
      this.classesMaterial.push(classe);
    }
  }

  detalheServico(idservico: any, nomeservico: any): void {
    // console.log('detalhar servico codigo serviço' + idservico);
    localStorage.setItem('Id', idservico);
    localStorage.setItem('Nome', nomeservico);
    this.tela1 = false;
    this.detalheServicolVisivel = true;
    // this.router.navigate(['/detalheservicosiasgnetlote/']);
  }


  detalheMaterial(codigopdm: any, nomepdm: any): void {
    console.log('detalhar pdm codigo pdm:' + codigopdm);

    localStorage.setItem('Id', codigopdm);
    localStorage.setItem('Nome', nomepdm);
    // this.router.navigate(['/detalhematerialsiasgnetlote/']);
    this.tela1 = false;
    this.telaVazia = false;
    this.detalheMaterialVisivel = true;
  }


  getItens(): Item[] {
    const saved = localStorage.getItem('itens');
    if (saved != null) {
      this.itens = JSON.parse(saved);
    }
    return this.itens;
  }
  selecionados(): void {
    //  this.router.navigate(['/exibirselecionadossiasgnetlote']);
    this.exibirMenssagemVazia = false;
    this.esconderTodos();
    this.exibirSelecinados = true;
  }

  liberarPesquisa(event: any): void {
    console.log(event.target.value);
    const teste = event.target.value;
    if (teste.length >= 2) {
      this.isDisabled = false;
    } else {
      this.isDisabled = true;
    }
  }
  async obterServicosPorPalavra(): Promise <any> {
    return new Promise((resolve, reject) => {
      this.service.obterServicosPorPalavra(this.texto).subscribe(servico => {
        if (servico) {
          const grupo = new Grupo();
          grupo.id = '0';
          grupo.descricao = 'todos os grupos';
          grupo.code = 0 ;
          grupo.name = 'TODOS OS GRUPOS';
          this.gruposServico.push(grupo);
          let tmp;
          for (tmp of servico) {
            this.servicos.push(tmp);
            this.servicosRetornados.push(tmp);
            this.carregarGrupoServico(tmp);
          }
        }
        this.servicos.sort((a: any, b: any) => {
          if(a.statusServico == true && b.statusServico == true){
            return 0;
          }
          if(a.statusServico == true && b.statusServico == false){
            return -1;
          }
          return 1;
        });
        if (this.servicos.length > 9){
          this.paginarServico = true;
        }else{
          this.paginarServico = false;
        }
        resolve(this.servicos.length);
      }, (error) => console.error(error));
    });
  }


  async obterPDMPorPalavra(): Promise <any> {
    return new Promise((resolve, reject) => {
      this.service.obterPDMPorPalavra(this.texto).subscribe(material => {
        if (material) {
          const classe = new Classe();
          classe.id = '0';
          classe.descricao = 'todas as classes';
          classe.name = 'TODAS AS CLASSES';
          this.classesMaterial.push(classe);
          let tmp;
          for (tmp of material) {
            this.materiais.push(tmp);
            this.materiaisRetornados.push(tmp);
            this.carregarClasseMaterial(tmp);
          }
          this.materiais.sort((a: any, b: any) => {
            if (a.statusPDM == true && b.statusPDM == true) {
              return 0;
            }
            if (a.statusPDM == true && b.statusPDM == false) {
              return -1;
            }
            return 1;
          });
          if (this.materiais.length > 9) {
            this.paginarMaterial = true;
          } else {
            this.paginarMaterial = false;
          }
          resolve(this.materiais.length);
        }
      }, (error) => console.error(error));
    });
  }


  grupoEscolhido(event: any): void {
    console.log('grupoEscolhido' + JSON.stringify(event.value.id));
    this.indexpainel = 0;
    this.servicos = [];
    let servicotmp;
    // const servicotodos = new Servico();

    if (event.value.id === '0') {
      for (servicotmp of this.servicosRetornados) {
        this.servicos.push(servicotmp);
      }
    } else {
      for (servicotmp of this.servicosRetornados) {
        if (servicotmp.codigoGrupo === event.value.id) {
          this.servicos.push(servicotmp);
        }
      }
    }
    this.servicos.sort((a: any, b: any) => {
      if (a.statusServico == true && b.statusServico == true) {
        return 0;
      }
      if (a.statusServico == true && b.statusServico == false) {
        return -1;
      }
      return 1;
    });
    if (this.servicos.length > 9) {
      this.paginarServico = true;
    } else {
      this.paginarServico = false;
    }
  }

  classeEscolhida(event: any): void {
    console.log(JSON.stringify(event.value.id));
    this.indexpainel = 1;
    this.materiais = [];
    let materialtmp;
    if (event.value.id === '0') {
      for (materialtmp of this.materiaisRetornados) {
        this.materiais.push(materialtmp);
      }
    } else {
      for (materialtmp of this.materiaisRetornados) {
        if (materialtmp.codigoClasse === event.value.id) {
          this.materiais.push(materialtmp);
        }
      }
    }
    this.materiais.sort((a: any, b: any) => {
      if (a.statusPDM == true && b.statusPDM == true) {
        return 0;
      }
      if (a.statusPDM == true && b.statusPDM == false) {
        return -1;
      }
      return 1;
    });
    if (this.materiais.length > 9) {
      this.paginarMaterial = true;
    } else {
      this.paginarMaterial = false;
    }
  }

  voltar($event: any): void {// limpar exibir home
    console.log('mechendo no mãe');
    this.tela1 = true;
    this.detalheMaterialVisivel = false;
    this.detalheServicolVisivel = false;
    this.exibirSelecinados = false;
  }

  onSortChange(event: any): void {
    const value = event.target.value;
    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
    } else {
      this.sortOrder = 1;
      this.sortField = value;
    }
  }

  resetArrays(): void {
    this.materiais = [];
    this.servicos = [];
    this.servicosRetornados = [];
    this.materiaisRetornados = [];
    this.gruposServico = [];
    this.selectedGrupos = [];
    this.classesMaterial = [];
    this.selectedClasses = [];
    localStorage.setItem('Id', '');
    localStorage.setItem('Nome', '');
    localStorage.setItem(('codigo_escolhido'), '');
  }

  esconderTodos(): void {
    this.tela1 = false;
    this.detalheMaterialVisivel = false;
    this.detalheServicolVisivel = false;
    this.exibirSelecinados = false;
    this.telaVazia = false;
    this.exibirMenssagemVazia = false;
  }

  voltaTelaInicial(): void {
    console.log('test');
    this.resetArrays();
    this.esconderTodos();
    this.tela1 = false;
    this.telaVazia = true;
  }

  customSort(event: any) {
    event.data.sort((data1: any, data2: any) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (event.field === 'id') {
        value1 = parseInt(value1);
        value2 = parseInt(value2);
      }
      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }

      return (event.order * result);
    });
  }

  // em desenvolviemnto

  async obterServicoPorCodigo(): Promise <any> {
    console.log(this.texto);
    return new Promise((resolve, reject) => {
      this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
        if (servico) {
          this.servicos.push(servico);
          this.servicosRetornados.push(servico);
          this.carregarGrupoServico(servico);
          this.tela1 = true;
        }
        resolve(this.servicos.length);
      }, (error) => console.error(error));
    });
  }
  async existeObterServicoPorCodigo(): Promise <any> {
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
        if (servico) {
          quantidade = 1;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  async obterMaterialPorCodigo(): Promise <any> {
    console.log(this.texto);
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterMaterialPorCodigo(this.texto).subscribe(materialRetorno => {
        if (materialRetorno) {
          localStorage.setItem('codigo_escolhido', this.texto);
          this.detalheMaterialVisivel = true;
          this.tela1 = false;
          quantidade = 1 ;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  async existeObterMaterialPorCodigo(): Promise <any> {
    console.log(this.texto);
    let quantidade = 0;
    return new Promise((resolve, reject) => {
      this.service.obterMaterialPorCodigo(this.texto).subscribe(materialRetorno => {
        if (materialRetorno) {
          quantidade = 1 ;
        }
        resolve(quantidade);
      }, (error) => console.error(error));
    });
  }

  seguirParaMaterial(): void {
    this.obterMaterialPorCodigo();
    this.modalRef1.hide();
  }

  seguirParaServico(): void {
    this.obterServicoPorCodigo();
    this.modalRef1.hide();
  }

  // todo: para remoção
  private async obterMateriaServicoPorCodigoNovo(): Promise<any> {
    // console.log('obterMateriaServicoPorCodigo' + this.texto);
    await this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
      if (servico) {
        this.servicos.push(servico);
        this.servicosRetornados.push(servico);
        this.carregarGrupoServico(servico);
        this.tela1 = true;
      }
    }, (error) => console.error(error));
    await this.service.obterMaterialPorCodigo(this.texto).subscribe(materialRetorno => {
      if (materialRetorno) {
        localStorage.setItem('codigo_escolhido', this.texto);
        // this.router.navigate(['/detalhematerialsiasgnetlote/']);
        this.detalheMaterialVisivel = true;
        this.tela1 = false;
      }
    }, (error) => console.error(error));
  }
  // private async obterMateriaServicoPorCodigo(): Promise<any> {
  //   // console.log('obterMateriaServicoPorCodigo' + this.texto);
  //   switch (this.filtro1.code) {
  //     case 1:
  //       this.messageService.add({severity:'warn', summary: 'Busca por código.', detail: 'Para busca por código, escolha material ou serviço.'});
  //       break;
  //     case 2:
  //       localStorage.setItem('codigo_escolhido', this.texto);
  //       this.router.navigate(
  //         ['/detalhematerialsiasgnetlote/']);
  //       break;
  //     case 3:
  //       await this.service.obterServicoPorCodigo(this.texto).subscribe(servico => {
  //         if (servico) {
  //           this.servicos.push(servico);
  //           this.servicosRetornados.push(servico);
  //           this.carregarGrupoServico(servico);
  //         }
  //       }, (error) => console.error(error));
  //       break;
  //   }
  // }



}

class Filtro {
  code: number;
  name: string;
}
// referências
// https://stackoverflow.com/questions/8217419/how-to-determine-if-javascript-array-contains-an-object-with-an-attribute-that-e
// https://angular.io/guide/user-input
// https://getbootstrap.com/docs/5.0/layout/gutters/

