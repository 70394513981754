import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {Service} from '../../service/service';
import {PDM} from '../model/pdm.model';
import {Caracteristica} from '../model/caracteristica';
import {ItemMaterial} from '../model/item-material';
import {FiltroMaterial} from '../model/filtro-material';
import {Item} from '../model/item.model';
import {MessageService} from 'primeng/api';
import {DropdownModule} from 'primeng/dropdown';
import { AuthService } from 'src/app/security/services/auth-service.service';
import { PesquisaService } from 'src/app/arealogada/pedido/pedido-catalogador.service';

@Component({
  selector: 'app-detalhe-material',
  templateUrl: './detalhe-material.component.html',
  styleUrls: ['./detalhe-material.component.css']
})
export class DetalheMaterialComponent implements OnInit, OnChanges {
  @Output() atualizarLista = new EventEmitter<any>();
  @Output() aoVoltar = new EventEmitter<any>();
  pdm: PDM = new PDM();
  codigoMaterialEscolhido: any;
  itens: ItemMaterial[] = [];
  itensSelecionados: ItemMaterial[] = [];
  itensSelecionadosTela: ItemMaterial[] = [];
  caracteristica: Caracteristica[] = [];
  fitrosSelecionados: FiltroMaterial[] = [];
  item: Item;
  itensArmazenados: Item[] = [];
  unidadesFornecimento: any;
  unidadeFornecimentoEscolhida: any;
  display = false;
  paginarMaterial = true ;
  rows = 10;
  classificacaoContabil: any[] = [];
  itensAtivos: number = 0;
  itensInativos: number = 0;
  ativos: boolean = false;
  codClasse: any;
  url: any;
  caracteristicasPedido: any[] = [];
  @Input()
  isUsuarioGovernoLogado: any;

  token: any;
  usrProfile:any;
  usrRoles:{[id:string]:boolean};


  constructor(public authService: AuthService,
              private route: ActivatedRoute,
              private location: Location,
              private service: Service,
              private router: Router,
              private messageService: MessageService,
              private servicePesquisa: PesquisaService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pdm.id = localStorage.getItem('Id');
    this.pdm.descricao = localStorage.getItem('Nome');
    this.codClasse = localStorage.getItem('codClasse');
    console.log('--- DetalheMaterialComponent ngOnChanges --  ');
  }

  ngOnInit(): void {
    this.token = this.authService.token;
    if (this.token){
      this.usrProfile=this.authService.getUserProfile(this.token);
      this.usrRoles=this.authService.getUserRoles(this.token);
    }

    console.log('DetalheMaterialComponent ngOnInit');
    // usar id pra fazer no backend passado
    // pegar o retorno e povar as dados
    this.codigoMaterialEscolhido = localStorage.getItem('codigo_escolhido'); // usado na pesquisa por código
    this.pdm.id = localStorage.getItem('Id');
    this.pdm.descricao = localStorage.getItem('Nome');
    this.codClasse = localStorage.getItem('codClasse');
    this.itens = [];
    this.itensSelecionados = [];
    this.caracteristica = [];
    this.fitrosSelecionados = [];
    this.itensArmazenados = [];
    this.classificacaoContabil = [];

    if (this.pdm.id) { // pesquisa normal por  pdm
      // this.service.obterCaracteristicasPorPDM(this.pdm.id).subscribe(x => this.caracteristica = x);
      this.service.obterCaracteristicasPorPDM(this.pdm.id).subscribe(caracteristicas => {
        if (caracteristicas) {
          for (const caracteristicatmp of caracteristicas) {
            this.caracteristica.push(caracteristicatmp);
          }
          if (caracteristicas.length === 0){
            this.messageService.add({
              severity: 'warn',
              summary: 'Este Material não possui Características Ativas.',
              detail: ''
            });
          }
          // Retirado por requisição do relato 3073737, do cliente - José Marcos
          //           this.caracteristica.sort((a, b) => (a.caracteristicaObrigatoria < b.caracteristicaObrigatoria) ? 1 : -1);
          for (const caracteristica of this.caracteristica) {
                      caracteristica.valorCaracteristica.sort(
              (a: { nomeValorCaracteristica: string; }, b: { nomeValorCaracteristica: string; }) =>
              (a.nomeValorCaracteristica < b.nomeValorCaracteristica) ? -1 : 1);
              // for criado para tirar os valores de caracteristicas inativas(falso)
              for (let ind = 0; ind < caracteristica.valorCaracteristica.length; ind++){
                if (!caracteristica.valorCaracteristica[ind].statusValorCaracteristicaPdm){
                  caracteristica.valorCaracteristica.splice(ind,1);
                  ind--;
                }
              }

              for (const valor of caracteristica.valorCaracteristica) {
                valor.valorLabel = valor.nomeValorCaracteristica;
                valor.valorConcat = valor.codigoValorCaracteristica;
                if (valor.siglaUnidadeMedida) {
                  valor.valorLabel = valor.valorLabel + ' ' + valor.siglaUnidadeMedida;
                  valor.valorConcat = valor.valorConcat + '¬' + valor.siglaUnidadeMedida;
                }
              }
          }
        }
      });

      this.service.obterUnidadeFornecimentoPorPDM(this.pdm.id).subscribe(x => {
        this.unidadesFornecimento = x
        if (this.unidadesFornecimento != null) {
          if (this.unidadesFornecimento.length === 1){
             this.unidadeFornecimentoEscolhida = this.unidadesFornecimento[0];
          }
        }
      });
      this.service.obterClassificacaoContabilPorCodigoPdm(this.pdm.id).subscribe(classificacaoContabil => {
          if (classificacaoContabil){
            for (const tmp of classificacaoContabil) {
              console.log(tmp.classificacaoContabilMaterial);
              this.classificacaoContabil.push(tmp.classificacaoContabilMaterial);
            }
          }
        }
      );
      this.service.obterItemMaterialCaracateríticasValorPorPDM(this.pdm.id).subscribe(item => {
        if (item) {
          for (const tmp of item) {
            this.itens.push(tmp); // coloco os dados array geral da pesquisa
            this.itensSelecionados.push(tmp); // coloco array da tela
          }
          this.itensSelecionados.sort((a: any, b: any) => {
            if(a.statusItem == true && b.statusItem == true){
              return 0;
            }
            if(a.statusItem == true && b.statusItem == false){
              return -1;
            }
            return 1;
          });
          if (this.itensSelecionados.length > 9){
            this.paginarMaterial = true;
          }else{
            this.paginarMaterial = false;
          }
          if (this.itens.length === 0){
            this.messageService.add({
              severity: 'warn',
              summary: 'Este Material não possui Ítens Ativos.',
              detail: ''
            });
          }
          for(let i of this.itensSelecionados){
            if(i.itemSuspenso == false && i.statusItem == true){
              this.itensAtivos = this.itensAtivos + 1;
            }
            if (i.statusItem == true && i.itemSuspenso == true){
              this.itensInativos = this.itensInativos + 1;
            }
          }
          this.somenteAtivos();
        }
      });
    }
    if (this.codigoMaterialEscolhido) { // pesquisa por id de material
      console.log('codigoMaterialEscolhido pesquisa por id de material' + this.codigoMaterialEscolhido);
      this.service.obterMaterialPorCodigo(this.codigoMaterialEscolhido).subscribe(materialRetorno => {
        if (materialRetorno) {
          this.itens.push(materialRetorno); // coloco os dados array geral da pesquisa
          this.itensSelecionados.push(materialRetorno); // coloco array da tela
          this.itensSelecionados.sort((a: any, b: any) => {
            if(a.statusItem == true && b.statusItem == true){
              return 0;
            }
            if(a.statusItem == true && b.statusItem == false){
              return -1;
            }
            return 1;
          });
          this.pdm.id = materialRetorno.codigoPdm;
          this.pdm.descricao = materialRetorno.nomePdm;
          this.service.obterUnidadeFornecimentoPorPDM(this.pdm.id).subscribe(x => {
            this.unidadesFornecimento = x
            if (this.unidadesFornecimento != null) {
              if (this.unidadesFornecimento.length === 1){
                this.unidadeFornecimentoEscolhida = this.unidadesFornecimento[0];
              }
            }
          });
          this.service.obterClassificacaoContabilPorCodigoPdm(this.pdm.id).subscribe(classificacaoContabil => {
              if (classificacaoContabil){
                for (const tmp of classificacaoContabil) {
                  console.log(tmp.classificacaoContabilMaterial);
                  this.classificacaoContabil.push(tmp.classificacaoContabilMaterial);
                }
              }
            }
          );

          for(let i of this.itensSelecionados){
            if(i.itemSuspenso == false && i.statusItem == true){
              this.itensAtivos = this.itensAtivos + 1;
            }
            if (i.statusItem == true && i.itemSuspenso == true){
              this.itensInativos = this.itensInativos + 1;
            }
          }
          this.somenteAtivos();

        }
      }, (error) => console.error(error));

    }
    this.getItensArmazenados();
    console.log('DetalheMaterialComponent ngOnInit fim');
  }

  getItens(): ItemMaterial[] {
    return this.itens;
  }

  private obterCaracteristicasPorPDM(itemtmp: any): void {

    for (const caracteristicatmp of itemtmp.buscaItemCaracteristica) {
      if (!this.caracteristica.some(e => e.codigoCaracteristica === caracteristicatmp.codigoCaracteristica)) {
        // se a caracteristica não existir cria a caracteristica e colocar o valorCaracteristica
        const caracteristica = new Caracteristica();
        console.log('criar novo para  ' + caracteristicatmp.codigoCaracteristica + '---------' + caracteristicatmp.nomeCaracteristica);
        caracteristica.nomeCaracteristica = caracteristicatmp.nomeCaracteristica;
        caracteristica.codigoCaracteristica = caracteristicatmp.codigoCaracteristica;
        caracteristica.caracteristicaObrigatoria = caracteristicatmp.caracteristicaObrigatoria;
        caracteristica.numeroCaracteristica = caracteristicatmp.numeroCaracteristica;
        caracteristica.siglaUnidadeMedida = caracteristicatmp.siglaUnidadeMedida;
        const valorCaracteristica = {
          codigoValorCaracteristica: undefined,
          nomeValorCaracteristica: undefined
        };
        valorCaracteristica.codigoValorCaracteristica = caracteristicatmp.codigoValorCaracteristica;
        valorCaracteristica.nomeValorCaracteristica = caracteristicatmp.nomeValorCaracteristica;
        caracteristica.valorCaracteristica = [];
        caracteristica.valorCaracteristica.push(valorCaracteristica);
        this.caracteristica.push(caracteristica);
      } else {
        console.log('ja existe localizar: codigo ' + caracteristicatmp.codigoCaracteristica + ' nome: ' + caracteristicatmp.nomeCaracteristica);
        console.log(' verificar se já term o valor cadastrado');
        for (const carac of this.caracteristica) {
          if (carac.codigoCaracteristica === caracteristicatmp.codigoCaracteristica) {
            console.log('quando forem igual');
            console.log(carac.codigoCaracteristica);
            console.log(caracteristicatmp.codigoCaracteristica);
            let jaTemValorCaracterisisca = false;
            for (const valorCaracteristica of carac.valorCaracteristica) {
              console.log(valorCaracteristica.codigoValorCaracteristica + '--' + valorCaracteristica.nomeValorCaracteristica);
              if (valorCaracteristica.codigoValorCaracteristica === caracteristicatmp.codigoValorCaracteristica) {
                console.log('ja tem o valor característica');
                jaTemValorCaracterisisca = true;
              }
            }
            if (jaTemValorCaracterisisca === false) {
              const valorCaracteristica = {
                codigoValorCaracteristica: undefined,
                nomeValorCaracteristica: undefined,
                siglaUnidadeMedida: undefined
              };
              valorCaracteristica.codigoValorCaracteristica = caracteristicatmp.codigoValorCaracteristica;
              valorCaracteristica.nomeValorCaracteristica = caracteristicatmp.nomeValorCaracteristica;
              valorCaracteristica.siglaUnidadeMedida = caracteristicatmp.siglaUnidadeMedida;  //  JMD
              carac.valorCaracteristica.push(valorCaracteristica);
            }
          }
        }
      }
      // se já existir, ataulaiza
    }
    this.caracteristica.sort((a,b) => a.valorCaracteristica.nomeValorCaracteristica.localeCompare(b.valorCaracteristica.nomeValorCaracteristica));
  }
  getItensArmazenados(): Item[] {
    const saved = localStorage.getItem('itensbusca');
    if (saved != null) {
      this.itensArmazenados = JSON.parse(saved);
    }

    return this.itensArmazenados;
  }

  escolher(codigoItem: number): void {
    // tenho o id, tenho que pegar o nome
    let nomeCompleto = '';
    let carrinhoNome = '';
    let carrinhoCaracteristicas = '';
    let sustentavel: boolean = false;
    let codigoNcm: string = '';
    let aplicaMargemPreferencia:boolean = false;

    for (const item of this.itensSelecionados) {
      if (item.codigoItem === codigoItem) {
        nomeCompleto = item.nomePdm;
        carrinhoNome =  item.nomePdm;
        sustentavel = item.itemSustentavel;
        codigoNcm = item.codigoNcm;
        aplicaMargemPreferencia = item.aplicaMargemPreferencia;
        for (const buscaItemCaracteristica of item.buscaItemCaracteristica) {
          nomeCompleto = nomeCompleto + ' ' + buscaItemCaracteristica.nomeCaracteristica + ': ' +
            buscaItemCaracteristica.nomeValorCaracteristica + " " + (buscaItemCaracteristica.siglaUnidadeMedida == null ? "" : buscaItemCaracteristica.siglaUnidadeMedida) + ',';
            if (buscaItemCaracteristica.statusCaracteristica == true){
                 carrinhoCaracteristicas  =  carrinhoCaracteristicas + '#' + buscaItemCaracteristica.nomeCaracteristica + ': ' +
                 buscaItemCaracteristica.nomeValorCaracteristica + " " + (buscaItemCaracteristica.siglaUnidadeMedida == null ? "" : buscaItemCaracteristica.siglaUnidadeMedida) + '|';
            }else{
                 carrinhoCaracteristicas  =   carrinhoCaracteristicas + '&' + buscaItemCaracteristica.nomeCaracteristica + ': ' +
                 buscaItemCaracteristica.nomeValorCaracteristica +  " " + (buscaItemCaracteristica.siglaUnidadeMedida == null ? "" : buscaItemCaracteristica.siglaUnidadeMedida) + '|';
            }

        }
        for (let i = nomeCompleto.length; i >= 0; i--) {
            if (nomeCompleto.substring(i) === ',') {
               nomeCompleto = nomeCompleto.substring(0, i - 1 );
               break;
            }
        }
      }
    }
    if (this.unidadeFornecimentoEscolhida === null || this.unidadeFornecimentoEscolhida === undefined || this.unidadeFornecimentoEscolhida === ''){
      this.messageService.add({
        severity: 'warn',
          summary: 'Não é possível adicionar um material sem uma unidade de fornecimento.',
          detail: 'Escolha uma unidade de fornecimento.'
      });
      return;
    }
    const nomeUnidadeMedida = {
      nomeUnidadeMedida: this.unidadeFornecimentoEscolhida.nomeUnidadeFornecimento
    };

    if (this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== undefined &&
        this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== null &&
        this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== 0) {
      nomeUnidadeMedida.nomeUnidadeMedida += ' ' + this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida;
    }
    if (this.unidadeFornecimentoEscolhida.siglaUnidadeMedida !== null && this.unidadeFornecimentoEscolhida.siglaUnidadeMedida !== undefined) {
      nomeUnidadeMedida.nomeUnidadeMedida += ' ' + this.unidadeFornecimentoEscolhida.siglaUnidadeMedida;
    }

    this.item = new Item();
    this.item.id = codigoItem;
    this.item.nome = nomeCompleto;
    this.item.carrinhoNome = carrinhoNome;
    this.item.itemSustentavel = sustentavel;
    this.item.carrinhoCaracteristicas = carrinhoCaracteristicas;
    this.item.unidade = nomeUnidadeMedida;
    this.item.tipo = 'MATERIAL';
    this.item.codigoNcm = codigoNcm;
    this.item.aplicaMargemPreferencia = aplicaMargemPreferencia;
    this.getItensArmazenados();
    this.itensArmazenados.push(this.item);

    localStorage.setItem('itensbusca', JSON.stringify(this.itensArmazenados));
    this.atualizarLista.emit();

    this.messageService.add({
      severity: 'success',
      summary: 'Item armazenado na lista.'
    });
  }

  naovalidar(codigoItem: number): boolean {
    for (const item of this.itensArmazenados) {
      if (item.id === codigoItem) {
        return true;
      }
    }
    return false;
  }

  voltar(): void {

    // this.location.back();
    this.aoVoltar.emit();
  }

  mudarFiltroDropDown(event: any, codigoCaracteristica: string, numeroCaracteristica: number): void {
    // JMD
    // inicio do filtro, limpar o arrays de filtros
    this.itensSelecionados = [];
    this.itensSelecionados = this.itens;
    let novosSelecionados = this.itensSelecionados;
    /*
     se  o codigo valor caracteristica for 0, um filtro foi retirado
    */
    if (event.value === null) {
      // retirar o filtro da lista de acordo com o código da característica
      this.fitrosSelecionados = this.fitrosSelecionados.filter(x => x.codigoCaracteristica !== codigoCaracteristica);
    } else {
      /* se  o codigo valor caracteristica for diferente de 0,
      significa que é filtro novo ou troca de valor de filtro
      verificar se o filtro já existe e neste caso trocar o valor,
      senão criar novo filtro
     */
    let codigoConcat = event.value.split('¬');
      if (this.fitrosSelecionados.some(x => x.codigoCaracteristica === codigoCaracteristica)) {
        for (const filtro of this.fitrosSelecionados) {
          if (filtro.codigoCaracteristica === codigoCaracteristica) {
            filtro.codigoValorCaracteristica = codigoConcat[0];
            filtro.siglaUnidadeMedida = codigoConcat[1];
            if(filtro.siglaUnidadeMedida == undefined){
              filtro.siglaUnidadeMedida = null;
            }
          }
        }
      } else {
        const filtro = new FiltroMaterial();
        filtro.codigoValorCaracteristica = codigoConcat[0];
        filtro.codigoCaracteristica = codigoCaracteristica;
        filtro.siglaUnidadeMedida = codigoConcat[1];
        if(filtro.siglaUnidadeMedida == undefined){
          filtro.siglaUnidadeMedida = null;
        }
        this.fitrosSelecionados.push(filtro);
      }
    }
    /*
      recarregar tudo a partir da lista de filtro atualizada
    */
    for (const filtrotmp of this.fitrosSelecionados) {
      const listaItnes: ItemMaterial[] = [];
      for (const item of novosSelecionados) {
        for (const buscaItemCaracteristica of item.buscaItemCaracteristica) {
          if (buscaItemCaracteristica.codigoCaracteristica === filtrotmp.codigoCaracteristica) {
            if (buscaItemCaracteristica.codigoValorCaracteristica === filtrotmp.codigoValorCaracteristica
              && buscaItemCaracteristica.siglaUnidadeMedida == filtrotmp.siglaUnidadeMedida) {
              if (!(listaItnes.some(x => x.codigoItem === item.codigoItem))) {
                listaItnes.push(item);
              }
            }
          }
        }
      }
      novosSelecionados = [];
      novosSelecionados = listaItnes;
    }
    this.itensSelecionados = [];
    this.itensSelecionados = novosSelecionados;
    if (this.itensSelecionados.length === 0) {  // se a lista de itens fica vazia,
      this.messageService.add({ severity: 'error', summary: ' Nenhum Item Existente com as características escolhidas', detail: 'Escolha outro valor para essa Característica.' });
    }
    this.itensAtivos = 0;
    this.itensInativos = 0;
    for(let i of this.itensSelecionados){
      if(i.statusItem == true && i.itemSuspenso == false){
        this.itensAtivos = this.itensAtivos + 1;
      }
      if (i.statusItem == true && i.itemSuspenso == true){
        this.itensInativos = this.itensInativos + 1;
      }
    }
    this.somenteAtivos();
  }

  selecionados(): void {
    this.router.navigate(['/exibirselecionados']);
  }

  limparFiltros(): void{
    console.log('limpar filtros');
    this.ngOnInit();
  }

  somenteAtivos(){
    let auxArray = [];
    this.itensSelecionadosTela = [];
    if(this.ativos == false){
      for(let i of this.itensSelecionados){
       if(i.statusItem == true && i.itemSuspenso == false){
          auxArray.push(i);
       }
      }
    } else {
        for(let i of this.itensSelecionados){
          if(i.statusItem == true){
              auxArray.push(i);
          }
        }
        auxArray.sort((a, b) => (a.itemSuspenso > b.itemSuspenso) ? -1 : 1);
    }
    this.itensSelecionadosTela = auxArray;
  }

  abrirEfetuarPedido() {
    this.montarArrayCaracteristicas();
    const detalhesPedido = {
      pdm: this.pdm.id,
      nomePdm: this.pdm.descricao,
      caracteristicas: this.caracteristicasPedido,
      tipoPedido: 'M',
      area: 'S'
    };
    this.servicePesquisa.criarPedido(detalhesPedido).subscribe((pedido) => {
      this.url = this.obterUrl();
      this.router.navigate([]).then(result =>
        {  window.open(this.url + pedido.numeroPedido, '_blank'); });
    }, (error) => {
      this.messageService.add({
        severity: 'error',
        summary: 'Erro ao criar pedido. Tente novamente.'
        });
    });
  }

  montarArrayCaracteristicas(){
    this.caracteristicasPedido = [];
    let auxUnidadeFornecimento;
    auxUnidadeFornecimento = 'Unidade de Fornecimento:';
    if(this.unidadeFornecimentoEscolhida !== null && this.unidadeFornecimentoEscolhida !== undefined ) {
      if (this.unidadeFornecimentoEscolhida.nomeUnidadeFornecimento !== undefined){
       auxUnidadeFornecimento = auxUnidadeFornecimento + ' ' + this.unidadeFornecimentoEscolhida.nomeUnidadeFornecimento;
      }
    }
      if((this.unidadeFornecimentoEscolhida !== null && this.unidadeFornecimentoEscolhida !== undefined ) &&
       (this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== undefined &&
       this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== null )) {
       if (this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida !== 0){
         auxUnidadeFornecimento = auxUnidadeFornecimento + ' ' + this.unidadeFornecimentoEscolhida.capacidadeUnidadeMedida;
       }
    }
    if((this.unidadeFornecimentoEscolhida !== null && this.unidadeFornecimentoEscolhida !== undefined ) &&
      this.unidadeFornecimentoEscolhida.nomeUnidadeMedida !== undefined &&
      this.unidadeFornecimentoEscolhida.nomeUnidadeMedida !== null) {
      auxUnidadeFornecimento = auxUnidadeFornecimento + ' ' + this.unidadeFornecimentoEscolhida.nomeUnidadeMedida;
    }
    this.caracteristicasPedido.push(auxUnidadeFornecimento);

    for (const caracteristicatmp of this.caracteristica){
      // mostrar apenas as que tem valor de caracteristica, as que nao tem nao devem ser mostradas no pedido - Esteves 06/05/2024
     if (caracteristicatmp.statusCaracteristica == true && caracteristicatmp.valorCaracteristica.length > 0){
      let auxString;
      auxString = caracteristicatmp.nomeCaracteristica;
      if (caracteristicatmp.caracteristicaObrigatoria){
        auxString = auxString + ' - obrigatório';
      }
      auxString = auxString + ': ';
      for (const filtro of this.fitrosSelecionados) {
        if(caracteristicatmp.codigoCaracteristica == filtro.codigoCaracteristica){
          for (const valor of caracteristicatmp.valorCaracteristica){
            if (valor.codigoValorCaracteristica == filtro.codigoValorCaracteristica &&
              valor.siglaUnidadeMedida == filtro.siglaUnidadeMedida) {
              auxString = auxString + valor.valorLabel;
            }
          }
        }
      }
      this.caracteristicasPedido.push(auxString);
     }
    }
  }

  obterUrl(): string {
    const href: string = window.location.href;
    if (href.indexOf('localhost') > 0){
      return  'http://localhost:4200/pedido/'
    }
    if (href.indexOf('des-cnbs') > 0 || href.indexOf('des.cnbs') > 0){
      return 'https://des-cnbs.estaleiro.serpro.gov.br/cnbs-web/pedido/';
    }
    if (href.indexOf('tre.cnbs') > 0 || href.indexOf('treinamento.catalogo') > 0 || href.indexOf('tre-cnbs') > 0){
      return 'https://treinamento.catalogo.compras.gov.br/cnbs-web/pedido/';
    }
    if (href.indexOf('hom.cnbs') > 0 || href.indexOf('hom-cnbs') > 0){
      return 'https://hom-cnbs.estaleiro.serpro.gov.br/cnbs-web/pedido/';
    }
    return 'https://catalogo.compras.gov.br/cnbs-web/pedido/';
  }
}
