import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, CanActivateChild } from '@angular/router';
import {AuthService} from '../../security/services/auth-service.service';
import { RouteDataService } from 'src/app/service/route-data.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService, private router: Router, private rds: RouteDataService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log('AuthGuard.canActivate');
    return this.avaliaAcesso(route, state);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.avaliaAcesso(childRoute, state);
  }


  avaliaAcesso(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const cnetId: any = route.queryParamMap.get('cnet-id');
    const expectedRole = route.data['expectedRole'];
    const expectedMnemonicos = route.data['expectedMnemonicos'];
    // Se não tem cnet-id significa que pode prosseguir com o processo de avaliação da sessão já existente
    if (!cnetId) {
      this.authService.setIsLoginProcessado(true);
    }

    // se nova autenticação
    if (cnetId) {
      this.authService.limparDadosSessaoUsuarioRedirecionar();
      const urlRetorno = this.getUrlSemCnetId(state.url);
      this.iniciarSessao(cnetId, urlRetorno);
      return false;
    } else if (this.authService.isAutenticadoAutorizado(expectedRole, expectedMnemonicos) && this.authService.isRefreshTokenValido()) {
      // se está logado
      return true;
    // } else if (this.authService.isRefreshTokenValido()) {
    //   // se o refresh token ainda está valido ou acesso não autorizado
    //   return true;
    } else {
      // se sessão expirada
    //   console.log('vou encerrar sessão ');
      this.encerrarSessao();
      return false;
    }
  }


  // tslint:disable-next-line:typedef
  private iniciarSessao(cnetId: string, urlRetorno: string) {
    console.log('AuthGuard iniciarSessao' + cnetId );
    this.router.navigate(
      ['iniciar-sessao', {
          'cnet-id': cnetId,
          urlRetorno
        }
      ],
      { skipLocationChange: true }
    );
  }

  // tslint:disable-next-line:typedef
  private encerrarSessao() {
    this.authService.logout('/acesso-nao-autorizado');
  }

  // tslint:disable-next-line:typedef
  private getUrlSemCnetId(urlCompleta: string) {
    const urlArray: string[] = urlCompleta.split('?');
    const parametrosArray: string[] = urlArray[1].split('&');

    let url = urlArray[0];
    const parametros: string[] = [];
    parametrosArray.forEach(parametro => {
      if (parametro.split('=')[0] !== 'cnet-id') {
        parametros.push(parametro);
      }
    });

    if (parametros.length > 0) {
      url += '?' + parametros.join('&');
    }

    return url;
  }
}
